import React from 'react';

const Internships = () => {
  const internshipList = [
    'Python Internship',
    'Java Internship',
    'Frontend Development Internship',
    'Backend Development Internship',
    'Full Stack Development Internship',
    'Data Science Internship',
    'Machine Learning Internship',
    'Cybersecurity Internship',
    'DevOps Internship',
    'Mobile App Development Internship',
    'UI/UX Design Internship',
    'Cloud Computing Internship',
    'Blockchain Internship',
    'AI Internship',
    'Database Management Internship',
    'Software Testing Internship',
    'All Available Internships in All Domains'
  ];

  return (
    <div className='text-center my-2'>
      <h1>Virtual Internships</h1>
      <ul>
        {internshipList.map((internship, index) => (
          <li key={index}>{internship}</li>
        ))}
      </ul>
      <a href='https://forms.gle/zf1hQ1PNRSkzjMQ2A'><button style={{color:'white',backgroundColor:'#4b0082',height:'2.5rem',borderRadius:'20px'}}>Click here to register</button></a>
    </div>
  );
};

export default Internships;
