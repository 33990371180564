import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Alert } from 'react-bootstrap';
import config from '../../config';

const Verified = ({ certId }) => {
  const [verificationResult, setVerificationResult] = useState(null);
  const [error, setError] = useState('');

  const baseURL =
    process.env.NODE_ENV === "development"
      ? config.LOCAL_BASE_URL
      : config.BASE_URL;
 console.log(baseURL)
  useEffect(() => {
    const verifyCertificate = async () => {
      try {
        const response = await axios.get(`${baseURL}verify/${certId}`);
        setVerificationResult(response.data);
      } catch (err) {
        setError('Error verifying certificate');
      }
    };

    verifyCertificate();
  }, [certId, baseURL]);

  return (
    <Container className="mt-3 mb-3">
      <h3>Verified Certificate</h3>
      {verificationResult ? (
        verificationResult.valid ? (
          <div>
            <p>Certificate ID: {certId}</p>
            <p>Name: {verificationResult.data.name}</p>
            <p>Course: {verificationResult.data.course}</p>
            <p>Issued Date: {verificationResult.data.issuedDate}</p>
            {/* Add more fields as needed */}
          </div>
        ) : (
          <Alert variant="danger">Certificate not found</Alert>
        )
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <p>Loading...</p>
      )}
    </Container>
  );
};

export default Verified;
