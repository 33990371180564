import React from 'react'
import Home from '../LMS/pages/Home'

const LMS = () => {
  return (
    <>
        <Home/>
    </>
  )
}

export default LMS