import React, { useState } from "react";
import {
  Container,
  Nav,
  NavDropdown,
  Button,
  Offcanvas,
} from "react-bootstrap";
import Logo from "../../images/blackorcade.png"; // Make sure this path is correct
import { FaBars } from "react-icons/fa";
import "./Header.css";

const LMSHeader = ({ isLoggedIn }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div style={{ backgroundColor: "black" }}>
      <Container className="d-flex align-items-center justify-content-between p-3">
        <div className="d-flex align-items-center h-100 w-50 p-2">
          <a href="/LMS">
            {" "}
            <img
              src={Logo}
              alt="OrcadeHub Logo"
              style={{ height: "60px", marginRight: "10px" }}
            />
          </a>
          <h1
            className="mb-0 orcadehub-title"
            style={{
              color: "#6A0DAD",
              fontWeight: "bolder",
              whiteSpace: "nowrap",
            }}
          >
            ORCADE HUB
          </h1>
        </div>

        {/* Show the normal navigation for screens larger than 768px */}
        <div className="d-none d-md-flex align-items-center justify-content-end w-50 gap-3">
          <NavDropdown
            title="Tutorials"
            id="nav-dropdown"
            className="text-white"
          >
            <NavDropdown.Item href="/LMS">
              Video Tutorials
            </NavDropdown.Item>
            <NavDropdown.Item href="/LMS">Docs</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/LMS" className="text-white">
            CodeHub
          </Nav.Link>
          <Nav.Link href="/LMS/certificates" className="text-white">
            Certificates
          </Nav.Link>
          {isLoggedIn ? (
            <button
              className="profile-icon"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className="text-primary" style={{ fontSize: "24px" }}>
                U
              </span>
            </button>
          ) : (
            <>
              <Button
                href="/LMS/login"
                className="me-2 border"
                style={{
                  borderRadius: "15px",
                  backgroundColor: "#6a0dad",
                  width: "10rem",
                  height: "2.5rem",
                }}
              >
                Login
              </Button>
              <Button
                href="/LMS/login"
                className="border"
                style={{
                  borderRadius: "15px",
                  backgroundColor: "#6a0dad",
                  width: "10rem",
                  height: "2.5rem",
                }}
              >
                Get Started
              </Button>
            </>
          )}
        </div>

        {/* Show a menu button for screens smaller than 768px */}
        <div className="d-flex d-md-none align-items-center justify-content-end">
          <Button
            variant="outline-light"
            onClick={handleShow}
            className="border-0"
          >
            <FaBars style={{ fontSize: "24px", color: "#fff" }} />
          </Button>
        </div>
      </Container>

      {/* Offcanvas for the mobile menu */}
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <NavDropdown
            title="Tutorials"
            id="nav-dropdown-mobile"
            className="mb-3"
          >
            <NavDropdown.Item href="/video-tutorials">
              Video Tutorials
            </NavDropdown.Item>
            <NavDropdown.Item href="/docs">Docs</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/codehub" className="mb-3">
            CodeHub
          </Nav.Link>
          <Nav.Link href="/LMS/certificates" className="mb-3">
            Certificates
          </Nav.Link>
          {isLoggedIn ? (
            <button
              className="profile-icon mb-3"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className="text-primary" style={{ fontSize: "24px" }}>
                U
              </span>
            </button>
          ) : (
            <>
              <Button
                variant="outline-light"
                href="/LMS/login"
                className="mb-3 w-100"
              >
                Login
              </Button>
              <Button variant="warning" href="/get-started" className="w-100">
                Get Started
              </Button>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default LMSHeader;
