import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import './CertiVerify.css';
import Verified from './Verified';
import Download from './Download';

const CertiVerify = () => {
    const [isVerify, setIsVerify] = useState(true);
    const [certId, setCertId] = useState('');
    const [showVerified, setShowVerified] = useState(false);
    const [showDownload, setShowDownload] = useState(false);

    const toggleForm = () => {
        setIsVerify(!isVerify);
        setShowVerified(false);
        setShowDownload(false);
    };

    const handleVerify = (event) => {
        event.preventDefault();
        setShowVerified(true);
        setShowDownload(false);
        console.log('Verifying certificate with ID:', certId);
    };

    const handleDownload = (event) => {
        event.preventDefault();
        setShowDownload(true);
        setShowVerified(false);
        console.log('Downloading certificate with ID:', certId);
    };

    return (
        <>
            <Container className="certi-container d-flex justify-content-center">
                <div className="form-wrapper shadow-lg">
                    <div className="toggle-switch">
                        <span className={`toggle-option ${isVerify ? 'active' : ''}`} onClick={toggleForm}>Verify</span>
                        <span className={`toggle-option ${!isVerify ? 'active' : ''}`} onClick={toggleForm}>Download</span>
                        <div className={`switch ${isVerify ? 'left' : 'right'}`} onClick={toggleForm}></div>
                    </div>
                    <div className={`form-container ${isVerify ? '' : 'flipped'}`}>
                        <div className="form-card">
                            <Form className="certi-form" onSubmit={handleVerify}>
                                <h2>Verify Certificate</h2>
                                <Form.Group controlId="verifyCertId">
                                    <Form.Label>Certificate ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Certificate ID"
                                        onChange={(event) => setCertId(event.target.value)}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className='mt-2 w-100' style={{ backgroundColor: '#6A0DAD' }}>
                                    Verify
                                </Button>
                            </Form>
                        </div>
                        <div className="form-card">
                            <Form className="certi-form" onSubmit={handleDownload}>
                                <h2>Download Certificate</h2>
                                <Form.Group controlId="downloadCertId">
                                    <Form.Label>Certificate ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Certificate ID"
                                        onChange={(event) => setCertId(event.target.value)}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className='mt-2 w-100' style={{ backgroundColor: '#6A0DAD' }}>
                                    Download
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </Container>
            {certId && showVerified && <Verified certId={certId} />}
            {certId && showDownload && <Download certId={certId} />}
        </>
    );
};

export default CertiVerify;
