import React from "react";
import { Button } from "react-bootstrap";
import "./FreeCard.css";
import Free from '../images/free.png';

const FreeCard = () => {
  const handleWhatsAppClick = () => {
    window.open("https://wa.me/917093012101", "_blank");
  };

  return (
    <div className="free-card">
      <div className="free-card-content">
        <h1 className="fade-in">First Time in the Entire World</h1>
        <h2 className="slide-in">
          We Offer Web and Mobile Development for Absolutely FREE FREE FREE
        </h2>
        <p className="fade-in">
          At OrcadeHub, we believe in empowering businesses and individuals by
          providing top-notch web and mobile app development services at no
          cost. Whether you're a startup, small business, or an individual
          entrepreneur, our team of experienced developers is here to bring your
          ideas to life with zero financial burden. This is a limited-time
          offer, so don't miss out!
        </p>
        <Button className="whatsapp-button zoom-in" onClick={handleWhatsAppClick}>
          Contact Us on WhatsApp
        </Button>
        <img src={Free} alt="free" className="free-logo bounce-in" />
      </div>
    </div>
  );
};

export default FreeCard;
