import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Logo from "../images/orcade.png";
import BlackLogo from "../images/blackorcade.png";
import Navbar from "react-bootstrap/Navbar";
import { FaBars } from "react-icons/fa6";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Header.css";

const Header = () => {
  const [activeLink, setActiveLink] = useState("#action1");
  const [scroll, setScroll] = useState(false);

  const handleSelect = (eventKey) => {
    setActiveLink(eventKey);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`header d-flex flex-column sticky-top ${
          scroll ? "scrolled" : ""
        }`}
        style={{ width: "100vw" }}
      >
        <Navbar expand="lg" className="border-bottom">
          <Container fluid>
            <div className="header_logo d-flex">
              <Nav.Link href="/">
                <img
                  src={scroll ? BlackLogo : Logo}
                  alt="logo"
                  className="me-1"
                />{" "}
              </Nav.Link>
              <Nav.Item>
                <Navbar.Brand
                  href="/"
                  className="nav-brand fs-1"
                  style={{ fontWeight: "bolder", color: "#6a0dad" }}
                >
                  ORCADE HUB
                </Navbar.Brand>
              </Nav.Item>
            </div>
            <div className="nav-links first-nav d-flex gap-5">
              <Nav.Link
                href="/"
                className="call_mail"
                style={{ fontSize: "18px", fontWeight: "bold" }}
              >
                +91 7093012101 ( AJITH )
              </Nav.Link>
              <Nav.Link
                href="/"
                className="call_mail"
                style={{ fontSize: "19px", fontWeight: "bold" }}
              >
                contact@orcadehub.com
              </Nav.Link>
              <div
                className="social_links d-flex gap-2"
                style={{ fontSize: "22px", fontWeight: "bold" }}
              >
                <a href="/LMS">
                  <button
                    style={{
                      backgroundColor: scroll ? "white" : "#6A0DAD",
                      color: scroll ? "#6A0DAD" : "white",
                      width: "5rem",
                      borderRadius: "15px",
                    }}
                  >
                    LMS
                  </button>
                </a>
              </div>
            </div>
          </Container>
        </Navbar>

        <div className="sec-navbar" >
          <Navbar className="mb-3" expand="lg" >
            <Container fluid>
              {/* Only show the toggle button on screens smaller than 1000px */}
              <Navbar.Toggle
                aria-controls="offcanvasNavbar-expand-lg"
                style={{ color: "orange", border: "none" }}
                className="toggle d-lg-none"
              >
                <FaBars />
              </Navbar.Toggle>
              {/* Show the Offcanvas only on screens smaller than 1000px */}
              <Navbar.Offcanvas
                id="offcanvasNavbar-expand-lg"
                aria-labelledby={"offcanvasNavbarLabel-expand-lg"}
                placement="top"
                className="offcanvas-nav d-lg-none"
              >
                <Offcanvas.Header
                  closeButton
                  className="header_logo_offcanvas custom-close-button d-flex justify-content-between align-items-center"
                >
                  <div className="d-flex justify-content-between" >
                    <Nav.Link href="/">
                      <img
                        src={Logo} // Switches logo on scroll
                        alt="logo"
                        style={{ width: "30px" }}
                        className="me-1"
                      />
                    </Nav.Link>
                    <Nav.Item>
                      <Navbar.Brand href="/" className="fw-bold fs-5" style={{color:'#6a0dad'}}>
                        ORCADE HUB
                      </Navbar.Brand>
                    </Nav.Item>
                  </div>
                  <div>
                    <a href="/LMS">
                      <button
                        style={{
                          backgroundColor: "#6A0DAD",
                          color: "white",
                          width: "5rem",
                          borderRadius: "15px",
                        }}
                      >
                        LMS
                      </button>
                    </a>
                  </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav
                    className="justify-content-start flex-grow-1 gap-3 py-2 second_nav"
                    activeKey={activeLink}
                    onSelect={handleSelect}
                    style={{ fontWeight: "600" }}
                  >
                    <Nav.Link href="/" className="nav-link" eventKey="#home">
                      Home
                    </Nav.Link>
                    <Nav.Link
                      href="/courses"
                      className="nav-link"
                      eventKey="#aboutus"
                    >
                      About Us
                    </Nav.Link>

                    <Nav.Link
                      href="/internships"
                      className="nav-link"
                      eventKey="#gallery"
                    >
                      Internships
                    </Nav.Link>

                    <Nav.Link
                      href="/courses"
                      className="nav-link"
                      eventKey="#courses"
                    >
                      Certification Courses
                    </Nav.Link>

                    <Nav.Link
                      href="/courses"
                      className="nav-link"
                      eventKey="#contactus"
                    >
                      Contact Us
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
              {/* Always show the bottom header on larger screens */}
              <Navbar.Collapse className="d-none d-lg-flex">
                <Nav
                  className="justify-content-start flex-grow-1 gap-3 py-2 second_nav"
                  activeKey={activeLink}
                  onSelect={handleSelect}
                  style={{ fontWeight: "600" }}
                >
                  <Nav.Link href="/" className="nav-link" eventKey="#home">
                    Home
                  </Nav.Link>
                  <Nav.Link
                    href="/courses"
                    className="nav-link"
                    eventKey="#aboutus"
                  >
                    About Us
                  </Nav.Link>

                  <Nav.Link
                    href="/internships"
                    className="nav-link"
                    eventKey="#gallery"
                  >
                    Internships
                  </Nav.Link>

                  <Nav.Link
                    href="/courses"
                    className="nav-link"
                    eventKey="#courses"
                  >
                    Certification Courses
                  </Nav.Link>

                  <Nav.Link
                    href="/courses"
                    className="nav-link"
                    eventKey="#contactus"
                  >
                    Contact Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    </>
  );
};

export default Header;
