import React from "react";
import { Container, Row, Col, Card, ListGroup, Badge } from "react-bootstrap";
import Logo from "../../images/orcade.png";
import Sign from "../../images/e-sign.png";
import './CertiDesign.css'
const CertiDesign = ({ certificateDetails }) => {
  return (
    <Container
      fluid
      className="certificate-container shadow-lg bg-dark-subtle"
      style={{ maxWidth: "60rem", minHeight: "43rem", marginTop: "20px" }}
    >
      <Row>
        {/* Key Skills Section */}
        <Col md={3} className="key-skills-section p-4 rounded-5">
          <Badge pill className="mb-3" style={{ backgroundColor: '#6A0DAD', color: 'white' }}>
            SPECIALIZED TRAININGS
          </Badge>
          <h3>Key Skills</h3>
          <ListGroup variant="flush" className="mt-3">
            {certificateDetails.skills.map((skill, index) => (
              <ListGroup.Item
                key={index}
                className="text-white rounded-5"
                style={{ backgroundColor: '#6A0DAD' }}
              >
                {skill}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>

        {/* Certificate Content Section */}
        <Col md={9} className="p-3">
          <Card className="certificate-content">
            <Card.Body>
              <div className="d-flex justify-content-center align-items-center flex-wrap">
                <img
                  src={Logo}
                  alt="orcadehub"
                  className="orcadehub-logo"
                  height={60}
                />
                <span
                  style={{
                    color: "#6A0DAD",
                    fontWeight: "bolder",
                    fontSize: "30px",
                    marginLeft: "10px",
                  }}
                >
                  ORCADE HUB
                </span>
              </div>
              <h3 className="mt-3 text-center">Course Certificate</h3>
              <div className="mt-4">
                <h4 className="text-center">{certificateDetails.name}</h4>
                <p className="text-center">
                  has successfully completed the {certificateDetails.duration}{" "}
                  course and training in
                </p>
                <h3 className="text-center">{certificateDetails.course}</h3>
                {/* <p className="text-center">
                  with an aggregate score of {certificateDetails.score}%.
                </p> */}
                <p>
                  This certificate is awarded in recognition of the successful
                  completion of an intensive and comprehensive learning program.
                  The recipient has demonstrated exceptional dedication,
                  discipline, and intellectual rigor throughout their studies.
                  Their commitment to excellence and perseverance in mastering
                  the subject matter have equipped them with the necessary
                  skills and knowledge to excel in their future endeavors.
                </p>
              </div>
              <div className="mt-4 text-right">
                <img
                  src={Sign}
                  alt="Signature"
                  className="signature ms-5"
                  height={50}
                />
                <div className="d-flex justify-content-around flex-wrap">
                  <p className="text-center">
                    Gurram Ajith Kumar
                    <br />
                    Founder & CEO, OrcadeHub
                  </p>
                  <p className="text-center">
                    Issued on: {certificateDetails.issuedDate} <br />
                    Certificate no.: {certificateDetails.certificateId}
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <p>
                  For certificate authentication, please visit{" "}
                  <a href="https://orcadehub.com/LMS/certificate" target="_blank" rel="noopener noreferrer">
                    Click here to verify
                  </a>
                </p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CertiDesign;
