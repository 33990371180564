import React from 'react'
import CertiVerify from '../components/CertiVerify'
const Certi = () => {
  return (
    <>
        <CertiVerify/>
    </>
  )
}

export default Certi