import './Testimonials.css';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { testimonials } from './Data';

const Testimonials = () => {
  return (
    <section className="testimonials" id='testi'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 info" style={{color:'#6A0DAD'}}>
            <h3>Feedback From Clients</h3>
            <p></p>
          </div>
          <div className="col-lg-7">
            <Swiper
              modules={[Pagination, Autoplay]}
              loop={true}
              speed={600}
              autoplay={{ delay: 5000 }}
              slidesPerView="auto"
              pagination={{
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
              }}
            >
              {testimonials.map((testimonial) => (
                <SwiperSlide key={testimonial.college} className="swiper-slide">
                  <div className="testimonial-item">
                    <div className="d-flex">
                      <img
                        src={testimonial.src}
                        alt="img"
                        className="testimonial-img flex-shrink-0"
                      />
                      <div>
                        <h3 style={{color:'#6A0DAD',fontWeight:'bolder'}}>{testimonial.college}</h3>
                        <h4 style={{color:'blue'}}>{testimonial.place}</h4>
                        <div className="stars">
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                          <i className="bi bi-star-fill"></i>
                        </div>
                      </div>
                    </div>
                    <p>
                      <i className="bi bi-quote right-icon"></i>
                      <span>{testimonial.comment}</span>
                      <i className="bi bi-quote left-icon"></i>
                    </p>
                  </div>
                </SwiperSlide>
              ))}
              <div className="swiper-pagination"></div>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
