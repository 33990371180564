import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import "./Login.css"

const Login = () => {
  const [isVerify, setIsVerify] = useState(true);
  const [id,setId]=useState("");
  console.log(id)
    const toggleForm = () => {
        setIsVerify(!isVerify);
    };

    
    return (
        <>
            <Container className="certi-container d-flex justify-content-center">
                <div className="form-wrapper shadow-lg">
                    <div className="toggle-switch">
                        <span className={`toggle-option ${isVerify ? 'active' : ''}`} onClick={toggleForm}>Login</span>
                        <span className={`toggle-option ${!isVerify ? 'active' : ''}`} onClick={toggleForm}>Signup</span>
                        <div className={`switch ${isVerify ? 'left' : 'right'}`} onClick={toggleForm}></div>
                    </div>
                    <div className={`form-container ${isVerify ? '' : 'flipped'}`}>
                        <div className="form-card">
                            <Form className="certi-form">
                                <h2>LOGIN</h2>
                                <Form.Group controlId="verifyCertId">
                                    {/* <Form.Label></Form.Label> */}
                                    <Form.Control
                                        type="text"
                                        placeholder="Email / Phone"
                                        onChange={(event) => setId(event.target.value)}
                                        className='mb-4'
                                        style={{border:'2px dashed #6a0dad'}}
                                    />
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        onChange={(event) => setId(event.target.value)}
                                        className='mb-4'
                                        style={{border:'2px dashed #6a0dad'}}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className='mt-2 w-100' style={{ backgroundColor: '#6A0DAD' }}>
                                    Verify
                                </Button>
                            </Form>
                        </div>
                        <div className="form-card">
                        <Form className="certi-form">
                                <h2>SIGN UP</h2>
                                <Form.Group controlId="verifyCertId">
                                    {/* <Form.Label></Form.Label> */}
                                    <Form.Control
                                        type="text"
                                        placeholder="Username"
                                        onChange={(event) => setId(event.target.value)}
                                        className='mb-4'
                                        style={{border:'2px dashed #6a0dad'}}
                                    />
                                    <Form.Control
                                        type="text"
                                        placeholder="Email / Phone"
                                        onChange={(event) => setId(event.target.value)}
                                        className='mb-4'
                                        style={{border:'2px dashed #6a0dad'}}
                                    />
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        onChange={(event) => setId(event.target.value)}
                                        className='mb-4'
                                        style={{border:'2px dashed #6a0dad'}}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className='mt-2 w-100' style={{ backgroundColor: '#6A0DAD' }}>
                                    Verify
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </Container>
           
        </>
    );
}

export default Login;