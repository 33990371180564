import Testimonials1 from "../images/profile.jpg";
import Testimonials2 from "../images/profile.jpg";
import Testimonials3 from "../images/profile.jpg";
import Testimonials4 from "../images/profile.jpg";
import Testimonials5 from "../images/profile.jpg";

export const testimonials = [
  {
    src: Testimonials1,
    college: "Mohan Babu University",
    place: "Tirupati",
    comment: "We had an excellent experience with the website development services provided by your team. The website is user-friendly, aesthetically pleasing, and meets all our requirements."
  },
  {
    src: Testimonials2,
    college: "Attain Smart",
    place: "Vijayawada",
    comment: "The personal and educational website developed for our company is top-notch. It is well-structured, easy to navigate, and has greatly improved our online presence."
  },
  {
    src: Testimonials3,
    college: "Mallareddy University",
    place: "Hyderabad",
    comment: "The Java Full Stack and C with Data Structures training provided by your team was exceptional. The curriculum was well-structured and the instructors were highly knowledgeable."
  },
  {
    src: Testimonials4,
    college: "KKR & KSR Institute of Technologies",
    place: "Guntur",
    comment: "We had a great experience with the Full Stack Web Development and Python Programming training. The interactive sessions and real-world examples helped our students grasp complex concepts easily."
  },
  {
    src: Testimonials5,
    college: "Mallareddy Engineering College for Women",
    place: "Hyderabad",
    comment: "The C and Python problem-solving training on HackerRank was very effective. Our students appreciated the detailed explanations and the practical approach to solving problems."
  }
];
