import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MbuLogo from "../images/Partners/mbu.jpeg";
import GdscMbuLogo from "../images/Partners/gdsc.png";
import AttainSmartLogo from "../images/Partners/as.jpg";
import GoogleLogo from '../images/Partners/google.png';
import Micro from '../images/Partners/micro.jpeg';

const companies = [
  {
    src: MbuLogo,
    name: "MBU",
  },
  {
    src: GdscMbuLogo,
    name: "GDSC-MBU",
  },
  {
    src: AttainSmartLogo,
    name: "Attain Smart",
  },
  {
    src: GoogleLogo,
    name: "Google",
  },
  {
    src: Micro,
    name: "Microsoft",
  },
];

const InfiniteSlider = () => {
  const settings = {
    infinite: true,
    speed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div style={{ margin: "0 auto", padding: "40px", width: "100%" }}>
      <h2 style={{ textAlign: "center", color: "#6A0DAD", fontWeight: "bold" }}>Collaborators</h2>
      <Slider {...settings}>
        {companies.map((company, index) => (
          <div key={index} style={{ padding: "10px" }}>
            <div
              style={{
                borderRadius: "50%",
                overflow: "hidden",
                width: "150px",
                height: "150px",
                margin: "0 auto",
                border: "5px solid #ddd",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
              }}
            >
              <img
                src={company.src}
                alt={company.name}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <h4 style={{ textAlign: "center", marginTop: "10px" }}>{company.name}</h4>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default InfiniteSlider;
