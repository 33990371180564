import React from "react";
// import { Link } from "react-router-dom";
import Testimonials from '../components/Testimonials';
import Companies from '../components/Companies';
import './Home.css'; // Import the CSS file
import FreeCard from "../components/FreeCard";
const Home = () => {
  return (
    <div style={{ textAlign: "center", fontWeight: "bold" }}>
     
      
      {/* Here create mobile responsive design card of width 100vw and height also 90vh showing
      content like we offer web and mobile development for absolutely free free free
      create a buttton when click on it move to whatsapp contact number +91 7093012101 */}
     <FreeCard/>
      <Companies/>
      <Testimonials/>
    </div>
  );
};

export default Home;
